
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import EolaMarketplace from 'library/pages/EolaMarketplace';
const MarketplacePage = () => <EolaMarketplace />;
export default MarketplacePage;

    async function __Next_Translate__getStaticProps__195fb45a577__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/marketplace',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195fb45a577__ as getStaticProps }
  